<template>
  <div class="container">

<div class="card">

</div>


  </div>
</template>

<script>
export default {
  data() {
    return {
  
    };
  },
  mounted() {
 
  },
  methods: {

  
  },

  computed: {
 
  }
};
</script>

<style scoped>


</style>
