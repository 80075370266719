<template>
  <div>
    <div class="info_data_wrapper">
      <div
        class="items_container"
        v-for="(item, key) in items"
        :key="key"
        :class="{ 'd-none': item.key == 'zoom_link' || item.key == 'status' }"
      >
        <span class="items_name">
          {{ $t(`Global.${item.key}`) }}
        </span>
        <span class="items_content"> {{ item.value }} </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoData",
  props: {
    items: null,
  },
};
</script>

<style lang="scss" scoped>
.info_data_wrapper {
  background-color: #fff;
  padding: 0.7rem;
  font-weight: 400;
  font-size: 1rem;
  color: #212529;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 30px;
  text-align: right;
  .items_container {
    padding: 12px 8px;
  }
  .items_container:nth-child(even) {
    // color: blue;
  }
  .items_container:nth-child(odd) {
    // color: red;
    background-color: rgba(0, 0, 0, 0.05);
  }
}
</style>
