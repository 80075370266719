import { render, staticRenderFns } from "./Login2.vue?vue&type=template&id=4c5ba0a2&scoped=true"
import script from "./Login2.vue?vue&type=script&lang=js"
export * from "./Login2.vue?vue&type=script&lang=js"
import style0 from "./Login2.vue?vue&type=style&index=0&id=4c5ba0a2&prod&scoped=true&lang=css"
import style1 from "./Login2.vue?vue&type=style&index=1&id=4c5ba0a2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c5ba0a2",
  null
  
)

export default component.exports