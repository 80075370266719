import Resource from "../api/resource";
import request from "../utils/request";



class School extends Resource {
  constructor() {
    super("school");
  }

  listOfSeats(query) {
    return request({
      url: `/schools/get/requests`,
      method: "post",
      params: query
    });
  }
  get_group_students(id, query) {
    return request({
      url: `groups/group/students/${id}`,
      method: "post",
      params: query
    });
  }
  listOfRegionAdmins(query) {
    return request({
      url: `/region/admins`,
      method: "post",
      params: query
    });
  }
  addRegionAdmin(payload) {
    return request({
      url: `/region/registeration/admin`,
      method: "post",
      data: payload
    });
  }

  // handlerAddRegionAdmin

  addSeats(payload) {
    return request({
      url: `/schools/add/request`,
      method: "post",
      data: payload
    });
  }

  regionAdminReport(query) {
    return request({
      url: `/region/admin/report`,
      method: "post",
      params: query
    });
  }
  schoolAdminReport(query) {
    return request({
      url: `/schools/admin/report`,
      method: "post",
      params: query
    });
  }
  classDetails(id) {
    return request({
      url: `/schools/get/group/${id}`,
      method: "post",
    });
  }
  addSchoolAdmin(payload) {
    return request({
      url: `/school/registeration/admin`,
      method: "post",
      data: payload
    });
  }
  getAllSchoolsAdmin(query) {
    return request({
      url: `/schools`,
      method: "post",
      params: query
    });
  }

  regionApproveSeats(id) {
    return request({
      url: `/schools/approve/region/request`,
      method: "post",
      data: id
    });
  }

  addSchoolClass(payload) {
    return request({
      url: `/schools/add/class`,
      method: "post",
      data: payload
    });
  }

  addStudent(payload) {
    return request({
      url: `/schools/add/student`,
      method: "post",
      data: payload
    });
  }
  getSchoolStudents(query) {
    return request({
      url: `/schools/get/student`,
      method: "post",
      params: query
    });
  }
  getSchoolStudentsNotInGroupDropdown() {
    return request({
      url: `/schools/get/not/in/group`,
      method: "post",
    });
  }
  addTeacher(payload) {
    return request({
      url: `/schools/add/teacher`,
      method: "post",
      data: payload
    });
  }
  getSchoolTeachers(query) {
    return request({
      url: `/schools/get/teachers`,
      method: "post",
      params: query
    });
  }
  getSchoolTeachersDropdown() {
    return request({
      url: `/schools/get/teachers/lookup`,
      method: "post",
    });
  }

  assignStudentsToGroup(payload) {
    return request({
      url: `/schools/add/students/group`,
      method: "post",
      data: payload
    });
  }
  assignTeacherToGroup(payload) {
    return request({
      url: `/schools/assign/teacher/group`,
      method: "post",
      data: payload
    });
  }

  adminApproveSeats(id) {
    return request({
      url: `/schools/approve/admin/request`,
      method: "post",
      data: id
    });
  }
  mainAdminReport(query) {
    return request({
      url: `/admin/report`,
      method: "post",
      params: query
    });
  }

  getRegionAdminSchools(region_admin_id, query) {
    // if (payload.region_admin_id != undefined) {
      return request({
        url: `/region/admins/schools/${region_admin_id}`,
        method: "post",
        // data:  payload.region_admin_id,
        params: query
      });
    // }
  }
  listHistoryOfSeats(query) {
    return request({
      url: `/schools/get/history/requests`,
      method: "post",
      params: query
    });
  }
  // delete teacher and students
  deleteTeacher(id) {
    return request({
      url: `/schools/delete/teacher/${id}`,
      method: "post",
    });
  }
  deleteStudent(id) {
    return request({
      url: `/schools/delete/student/${id}`,
      method: "post",
    });
  }
  // region admin schools report
  getRegionAdminSchoolsReport(id, query) {
      return request({
        url: `/region/admin/schools/report/${id}`,
        method: "post",
        params: query
      });
  }

  deleteSchool(id) {
    return request({
      url: `/region/school/delete/${id}`,
      method: "delete",
    });
  }
// update school
  updateRegionAdminSchool(payload,id) {
    return request({
      url: `/region/school/update/${id}`,
      method: "post",
      data: payload
    });
  }
  exportTemplate() {
    return request({
      url: `https://test.justtolearn.com/api/user/temp/download`,
      method: "get",
      responseType: "blob",
    });
  }
  exportTeachersTemplate() {
    return request({
      url: `https://test.justtolearn.com/api/user/teacher/temp/download`,
      method: "get",
      responseType: "blob",
    });
  }
}

export { School as default };


