<template>
<div>
      <exam :required="false" ></exam>
</div>
</template>

<script>


    import exam from "../courses/exam.vue"
    export default {
        name: "startexam",
        components: {exam},

    }
</script>

<style scoped>

</style>
