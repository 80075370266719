import Resource from '../api/resource';
import request from '../utils/request';

class Feedback extends Resource {
  constructor() {
    super('feedback');
  }


}

export {Feedback as default};
