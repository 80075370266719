<template>
<div>
  <h1></h1>
      <report></report>
</div>
</template>

<script>
  

    import report from "./components/report.vue"
    export default {
        name: "guestreport",
        components: {report},
       
    }
</script>

<style scoped>

</style>