import Checkbox from './Inputs/Checkbox.vue'
import Radio from './Inputs/Radio.vue'
import FormGroupInput from './Inputs/formGroupInput.vue'
import Switch from './Switch.vue'
import Progress from './Progress.vue'

import DropDown from './Dropdown.vue'
import Table from './Table.vue'

import FadeRenderTransition from './Transitions/FadeRenderTransition.vue'

import Card from './Cards/Teachers-Card.vue'
import ChartCard from './Cards/ChartCard.vue'
import StatsCard from './Cards/StatsCard.vue'

import Breadcrumb from './Breadcrumb/Breadcrumb.vue'
import BreadcrumbItem from './Breadcrumb/BreadcrumbItem.vue'

import Pagination from './Pagination.vue'

import SidebarPlugin from './SidebarPlugin'

let components = {
  Checkbox,
  Switch,
  Progress,
  Pagination,
  Radio,
  FormGroupInput,
  Card,
  ChartCard,
  StatsCard,
  Table,
  DropDown,
  SidebarPlugin,
  FadeRenderTransition,
  Breadcrumb,
  BreadcrumbItem
}
export {
  Checkbox,
  Switch,
  Progress,
  Pagination,
  Radio,
  FormGroupInput,
  Card,
  ChartCard,
  StatsCard,
  Table,
  DropDown,
  SidebarPlugin,
  FadeRenderTransition,
  Breadcrumb,
  BreadcrumbItem
}

export default components
