import { render, staticRenderFns } from "./order-neomi-kit.vue?vue&type=template&id=efcf7776&scoped=true"
import script from "./order-neomi-kit.vue?vue&type=script&lang=js"
export * from "./order-neomi-kit.vue?vue&type=script&lang=js"
import style0 from "./order-neomi-kit.vue?vue&type=style&index=0&id=efcf7776&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "efcf7776",
  null
  
)

export default component.exports