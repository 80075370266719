<template>
  <div class="main_app_vue" :class="{ is_english: getLocale != 'ar' }">
    <router-view></router-view>
  </div>
</template>

<script>
import NotificationSample from "src/pages/Dashboard/Components/NotificationSample";
export default {
  mounted() {
    // this.$notify(
    //   {
    //     component: NotificationSample,
    //     icon: 'nc-icon nc-app'
    //   })
  },
  computed: {
    getLocale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style>
.vs--searchable .vs__dropdown-toggle {
  padding: 6px;
}
.vs__selected-options {
  /* text-align: right; */
  /* background: #000; */
}
.vs__search {
  /* text-align: right; */
}
.vs_dropdown-menu {
  /* text-align: right; */
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.multiselect__tags {
  height: auto !important;
  min-height: 20px !important;
  padding: 5px 5px  !important;
}
.el-form-item__content {
  line-height: 22px;
}
.multiselect__option--selected:after {
  display: none;
}
.multiselect__option--highlight:after {
  display: none;
}
</style>

<style lang="scss">
.main_app_vue {
  direction: rtl;
  .multiselect {
    min-height: 20px !important;
    text-align: right;
  }
  &.is_english {
    direction: ltr;
    text-align: left !important;

    .multiselect {
      min-height: 20px !important;
      text-align: left;
    }
    .sidebar {
      left: 0;
      right: auto;
    }
    .main-panel {
      float: right;
      margin-left: 80px;
      margin-right: auto;
    }
  }
}
@media print {
  .sidebar {
    display: none;
  }
  .footer {
    display: none;
  }
}
</style>
