var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',[_c('table',{staticClass:"table table-striped text_nobreak w-100"},[_c('tbody',{staticStyle:{"text-align":"initial"}},[_vm._t("other_data"),_vm._l((_vm.items),function(item,key){return _c('tr',{key:key,staticClass:"w-100",class:{
            'd-none': _vm.shouldHideData(item.key),
          }},[_c('th',{attrs:{"scope":"row","width":'30%'}},[_vm._v(" "+_vm._s(_vm.$t(("GlobalGroup." + (item.key))))+" ")]),_c('td',[_c('span',[_vm._t("custom_show")],2),(
                item.key.includes('date') ||
                item.key.includes('close_at') ||
                item.key.includes('end_at') ||
                item.key.includes('starts') ||
                item.key.includes('expiration') ||
                item.key.includes('start') ||
                item.key.includes('created_at') ||
                item.key.includes('updated_at') ||
                item.key === 'from' ||
                item.key === 'to'
              )?_c('span',[_c('i',{staticClass:"el-icon-time"}),_vm._v(" "+_vm._s(_vm.toLocalDatetime(item.value, "en")))]):(item.key === 'gender')?_c('span',{staticClass:"text-break"},[_vm._v(" "+_vm._s(item.value == "1" ? _vm.$t("admin_dashboard_type.male") : _vm.$t("admin_dashboard_type.female"))+" ")]):(item.key === 'age_group')?_c('span',{staticClass:"text-break"},[_vm._v(" "+_vm._s(_vm.getAgeGroup(item.value))+" ")]):(item.key === 'status')?_c('span',{staticClass:"text-break"},[_vm._v(" "+_vm._s(_vm.getStatus(item.value))+" ")]):(item.key === 'days')?_c('span',{staticClass:"text-break"},_vm._l((item.value),function(day,i){return _c('span',{key:i,staticClass:"badge badge-success h6 mx-2 p-2 rounded-pill"},[_vm._v(" "+_vm._s(day)+" ")])}),0):(item.key === 'course_name')?_c('span',{staticClass:"text-break"},[_vm._v(" "+_vm._s(_vm.getCourseTitle(item.value))+" ")]):_c('span',{staticClass:"text-break"},[_vm._v(" "+_vm._s(item.value)+" ")])])])}),_vm._t("bottom_other_data")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }