<template>
  <el-table :data="studentData">
    <el-table-column prop="id" label="#" min-width="60"></el-table-column>
    <el-table-column prop="title" :label="$t('activity')" min-width="120"></el-table-column>
    <el-table-column prop="created_at" :label="$t('date')" width="130">
      <template slot-scope="scope">
        {{ scope.row.created_at ? toLocalDatetime(scope.row.created_at, 'en') : '_' }}
      </template>
    </el-table-column>
    <el-table-column prop="badge_id" :label="$t('badge')" min-width="120">
      <template slot-scope="scope">
        <i class="fa fa-shield fa-3x text-warning"></i>
        <p>{{ labels[scope.row.badge_id - 1] }}</p>
      </template>
    </el-table-column>
    <el-table-column prop="grade" :label="$t('evaluation')" min-width="150">
      <template slot-scope="scope">
        <el-rate v-model="scope.row.grade" disabled text-color="#ff9900"></el-rate>
      </template>
    </el-table-column>
    <el-table-column prop="status" :label="$t('admin_dashboard_type.status')" min-width="150">
      <template slot-scope="scope">
        <el-badge :value="activityStatus(scope.row.status).value" :type="activityStatus(scope.row.status).type"></el-badge>
      </template>
    </el-table-column>
    <el-table-column prop="" label="_" width="100">
      <template slot-scope="scope">
        <div class="d-flex justify-content-between">
          <i class="fa fa-edit text-info" style="cursor: pointer" @click="openStudentActivityDialog(scope.row)" v-tooltip.top-center="$t('review_active')"></i>
          <i class="fa fa-trash text-danger" @click="deleteActivityEvaluation(scope.row.id)" v-tooltip.top-center="$t('delete_active')"></i>
        </div>
      </template>
    </el-table-column>
  </el-table>

</template>

<script>
export default {
  data(){
    return {
    labels: [
        `${this.$t("no_eval")}`,
        `${this.$t("speed")}`,
        `${this.$t("perfection")}`,
        `${this.$t("quality")}`,
        `${this.$t("excellence")}`,
      ],
    };
  },
  props: {
    // tasks: {
    //   type: Array,
    //   required: true
    // },

  },

  computed: {
    studentData() {
    const studentId = this.$route.params.id; // Assuming the student ID is passed as a route parameter
    console.log("stuuu",this.$store.getters["groups/groupStudents"]);
    return this.$store.getters["groups/groupStudents"].find(student => student.id === studentId);
  }
  },
  methods: {
    activityStatus(status) {
      switch (status) {
        case 1:
          return {
            value: "Accepted",
            type: "success",
          };
        case 2:
          return {
            value: "Accepted With Modification",
            type: "warning",
          };
        case 3:
          return {
            value: "Resubmit",
            type: "danger",
          };
        default:
          return {
            value: "Pending",
            type: "warning",
          };
      }
    },
    openStudentActivityDialog(item, query) {
      // "https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif?20151024034921";
      let stuId = item.id;
      this.studentActivityDialog = true;
      // setTimeout(() => {
      //   this.editedStudentActivity = item;
      // }, 5000);
      this.editedStudentActivity = item;
      console.log("item",item);
      this.radio_status = item.status;
      //   this.$store.dispatch("groups/getGroupStudents", {
      //   id: this.$route.params.id,
      //   query,
      // }).then((res)=>{
      //   console.log(res)

      // })
    },
    deleteActivityEvaluation(id) {
      Swal.fire({
        text: `${this.$t("you_want_delete")}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: `${this.$t("confirm")}`,
        cancelButtonText: `${this.$t("cancel")}`,
        buttonsStyling: false,
      }).then((date) => {
        if (date.isConfirmed) {
          this.$store.dispatch("courses/evaluationDelete", id).then((_) => {
            this.Refresh();
            Swal.fire({
              text: `${this.$t("success_deleted")}`,
              icon: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
          });
        }
      });
    },
  }
};
</script>
