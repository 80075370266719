import Vue from "vue";
import Router from "vue-router";
import { isLogged, notHasPermissions } from "../utils/auth";

import StudentDetails from '../components/GroupStudents/StudentDetails';

// import { ROUTER_DEFAULT_CONFIG } from "Config/index";
// import { routerBeforeEachFunc } from "Config/interceptors/router";

Vue.use(Router);

import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue";
// GeneralViews
import NotFound from "src/pages/GeneralViews/NotFoundPage.vue";
// Dashboard pages
import Overview from "src/pages/Dashboard/Dashboard/Overview.vue";
// import Stats from 'src/pages/Dashboard/Dashboard/Stats.vue'

import Login from "src/pages/Dashboard/Pages/Login2.vue";
// import Register from 'src/pages/Dashboard/Pages/Register.vue'
import Register from "src/pages/Dashboard/Pages/Register2.vue";
// import photoGallery from "../views/pages/photoGallery.vue";
import RegisterGuest from "src/pages/Dashboard/Pages/RegisterGuest.vue";
import ResetPassword from "src/pages/Dashboard/Pages/ResetPassword.vue";
import Lock from "src/pages/Dashboard/Pages/Lock.vue";
import logout from "src/views/pages/Logout";

import intro from "src/views/pages/intro";
import examPage from "src/views/pages/exam-page";
import studentList from "src/views/pages/students-list";
import guest from "src/views/guest/index";
import results from "src/views/pages/results";
// import startExam from  "src/views/courses/start-exam";
import startExam from 'src/views/courses/start-exam';
import Report from 'src/views/guest/guest-report'
import VueRouter from "vue-router";
import NEOMIKIT from 'src/views/NemoiKit/order-neomi-kit.vue'

// {
//   name: "Order NEOMI KIT",
//   path: "order-neomi-kit",
//   component: () => import("../views/NemoiKit/order-neomi-kit.vue")
// },


let startexam = {

  path: "/start-exam/:exam/:id?",
  name: "startexam",
  component: startExam
};
let guestReport = {

  path: "/guest-report/:stu_id/:exam_type?",
  name: "guestreport",
  component: Report
}
// let startexam = {

//   path: "/start-exam/:exam/:id?",
//   name: "startexam",
//   component: startExam
// };


let loginPage = {
  path: "/login",
  name: "Login",
  component: Login
};


let exampage = {
  path: "/exam-page",
  name: "exam-page",
  component: examPage
};

let registerGuest ={
  path: "/guest-register",
  name: "register-guest",
  component: RegisterGuest
}



let introPage = {
  name: 'Intro',
  path: '/intro',
  component: intro
};
// let photosGallery = {
//   name: 'Photo Gallery',
//   path: '/photo-gallery',
//   component: photoGallery
// };

let resultPage = {
  name: 'results',
  path: '/results',
  component: results
};

let guestPage = {
  name: 'guest',
  path: '/exam/guests',
  component:guest,
};

let registerPage = {
  path: "/register",
  name: "Register",
  component: Register
};

let resetPasswordPage = {
  path: "/reset-password/:token",
  name: "ResetPassword",
  component: ResetPassword
};

let lockPage = {
  path: "/lock",
  name: "Lock",
  component: Lock
};

let orderNeomiKit = {
  name: "Order NEOMI KIT",
  path: "/order-neomi-kit",
  component: NEOMIKIT
};

const router = new VueRouter({
  routes: configRoutes(), // short for routes: routes
  mode: "history",
  linkActiveClass: "active"
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    !isLogged()
      ? next({ path: "/intro", params: { nextUrl: to.fullPath } })
      : next();
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  // scroll to top when rout change
  window.scrollTo(0, 0);
});

function configRoutes() {
  return [
    {
      path: "/",
      component: DashboardLayout,
      redirect: "/overview",
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: "/overview",
          // name: `${this.$t("success_deleted")}`,
          name: "Home",
          // name: "الصفحة الرئيسية",
          component: Overview
        },
      {
          name: 'Gallery',
          // name: 'معرض الصور',
          path: '/gallery',
          component: () => import("@/views/users/gallery"),
        },
        {
          path: "/logout",
          name: "تسجيل الخروج",
          component: logout
        },
        // user viewing his profile
        {
          name: "Profile",
          // name: "الصفحة الشخصية",
          path: "profile",
          component: () => import("@/views/users/show"),
          meta: {
            requiresAuth: true
          }
        },



        // user viewing another user profile as admin viewing ordinary user or parent view child profile
        {
          name: "Profile",
          // name: "الصفحة الشخصية",
          path: "user-profile/:id",
          component: () => import("@/views/users/ViewUserProfile"),
          meta: true
        },
        {
          name: "Users",
          // name: "المستخدمين",
          path: "users",
          component: () => import("@/views/users/index")
        },
        {
          name: "Edit Profile",
          // name: "تعديل البيانات الشخصية",
          path: "edit-user/:id?",
          component: () => import("@/views/users/edit")
        },
        {
          name: "Student Report",
          // name: "تقرير الطالب",
          path: "report/:stu_id/:exam_type",
          component: () => import("@/views/users/report")
        },
        {
          name: "New Teachers",
          // name: "المدرسين الجدد",
          path: "teachers",
          component: () => import("@/views/teachers/show")
        },
        {
          name: "Teachers",
          // name: "المدرسين",
          path: "/active_teacher",
          component: () => import("@/views/teachers/active")
        },
        {
          name: "Teachers",
          // name: "المراجعين",
          path: "auth/teachers",
          component: () => import("@/views/teachers/index")
        },
        {
          name: "Student Requests",
          // name: "طلبات الطلاب",
          path: "student/requests",
          component: () => import("../views/teachers/request")
        },
        {
          name: "Students",
          // name: "طلابى",
          path: "auth/student",
          component: () => import("@/views/teachers/student")
        },
        {
          name: "My Classes",
          // name: "مجموعاتي",
          path: "auth/groups",
          component: () => import("@/views/teachers/groups")
        },
        {
          name: "Students Class",
          // name: "طلبة المجموعة",
          path: "groups/group/students/:id",
          component: () => import("@/views/teachers/groupStudents")
        },
        {
          name: "Exam Correction",
          // name: "تصحيح اختبار",
          path: "exams/:exam_type/:id",
          component: () => import("@/views/teachers/examCorrection")
        },

        {
          name : "Results of the first test",
          // name : "نتائج الاختبار الاول",
          path: "score",
          component: () => import("@/views/teachers/score")
        },
        {
          name : "Student Profile",
          // name : "الملف الشخصي للطالب",
          path: "groups/group/students/profile/:id",
          component: () => import("@/views/teachers/ShowStudentPrfoile")
        },
        // start routes school for admin
        {
          name : "Schools",
          // name : "إدارة الفصول",
          path: "/school",
          component: () => import("@/views/school/Schools")
        },
        {
          name: "Students Class",
          // name: "طلبة الفصل",
          path: "/school/:id",
          component: () => import("@/views/school/StudentsClass")
        },
        {
          name: "Schools Report",
          // name: "تقارير المدارس",
          path: "/school-reports",
          component: () => import("@/views/school/SchoolReport")
        },
        {
          name: "Seats",
          // name: "المقاعد",
          path: "/assigned-quota",
          component: () => import("@/views/school/AssigedSchoolQuota")
        },
        {
          name: "Teachers",
          // name: "المدرسين",
          path: "/school-teachers",
          component: () => import("@/views/school/SchoolTeachers")
        },
        {
          name: "Students",
          // name: "الطلاب",
          path: "/school-students",
          component: () => import("@/views/school/SchoolStudents")
        },
        {
          name: "Dashboard ",
          path: "/school-dashboard",
          component: () => import("@/views/school/SchoolDashboard")
        },
        {
          name: "Class Report",
          // name: "تقرير الفصل",
          path: "/school-dashboard/:id",
          component: () => import("@/views/school/ClassDetails")
        },
        // end routes school for admin
        // start routes for region admin
        {
          name: "Region Dashboard",
          path: "/region-dashboard",
          component: () => import("@/views/region-admin/RegionDashboard")
        },
        {
          name: "Schools",
          // name: "المدارس",
          path: "/region-schools",
          component: () => import("@/views/region-admin/RegionSchools")
        },
        {
          name: "Seats",
          // name: "المقاعد",
          path: "/region-seats",
          component: () => import("@/views/region-admin/RegionSeats")
        },
        // end routes for region admin
        // start routes for admin
        {
          name: "Region Admin",
          // name: "أدمن المنطقة",
          path: "/region-admin",
          component: () => import("@/views/admin-pages/RegionAdmins")
        },
        {
          name: "Region Admin",
          // name: "أدمن المنطقة",
          path: "/region-admin/:id",
          component: () => import("@/views/admin-pages/RegionAdminDetails")
        },
        {
          name: "School Details",
          // name: "تفاصيل المدرسة",
          path: "/region-admin/:id/:school_id",
          component: () => import("@/views/admin-pages/RegionAdminSchoolReport")
        },
        {
          name: "Seats",
          // name: "المقاعد",
          path: "/admin-seats",
          component: () => import("@/views/admin-pages/AdminSeats")
        },
        {
          name: "Admin Dashboard",
          path: "/admin-dashboard",
          component: () => import("@/views/admin-pages/AdminDashboard")
        },
         // start routes for admin
        {
          name: "Courses",
          // name: "دوراتى التعلمية",
          path: "auth/courses",
          component: () => import("@/views/courses/index")
        },
        {
          name: "Groups",
          // name: "المجموعات",
          path: "groups",
          component: () => import("@/views/groups/index")
        },
        {

          name: "Courses",
          // name: "الدورات",
          path: "courses",
          component: () => import("../views/courses/courses")
        },
        {
          name: "Group Requests",
          // name: "طلبات المجموعات",
          path: "course/requests",
          component: () => import("../views/courses/request")
        },
        {
          name: "Course Details",
          // name: "تفاصيل الدورة",
          path: "course/:id/:group_id?",
          component: () => import("../views/courses/show")
        },


        {
          name: "Roles",
          // name: "المهام والوظايف",
          path: "roles",
          component: () => import("../views/permission/index")
        },
        {
          name: "Permission",
          // name: "تصاريح المستخدم",
          path: "permissions/:id",
          component: () => import("../views/permission/show")
        },
        {
          name: "Feedback",
          // name: "التغذية الراجعة",
          path: "feedback",
          component: () => import("../views/feedback/index")
        },
        {
          name: "Photo Gallery",
          // name: "صور من الهواتف",
          path: "photo-gallery",
          component: () => import("../views/photos-gallery/photoGallery.vue")
        },

        {
          name: "Message",
          // name: "الرسائل",
          path: "message",
          component: () => import("../views/pages/Message")
        },
        {
          name: "Tests",
          // name: "الاختبارات",
          path: "exams",
          component: () => import("../views/users/exams")
        },
        {
          name: "Neomi Test",
          // name: "اختبار نيومي",
          path: "neomi-exams",
          component: () => import("../views/neomi/NeomiExams")
        },
        {
          name: "Neomi Test",
          // name: "اختبار نيومي",
          path: "neomi-exams/:student_id/:challenge_no/:exam_type",
          component: () => import("../views/neomi/PrintNemoiExam")
        },
        {
          name: "Neomi Test",
          // name: " اختبار نيومي",
          path: "neomi-exam-correct/:student_id/:challenge_no/:exam_type_two",
          component: () => import("../views/neomi/PrintNemoiExamSecond")
        },
        {
          name: "Neomi Test Correction",
          // name: "تصحيح اختبار نيومي",
          path: "neomi-exam/:id/:exam_type/:challenge_no",
          component: () => import("../views/neomi/StudentExamDetails")
        },
        {
          name: "Neomi Test Correction",
          path: "pre-neomi",
          component: () => import("../views/neomi/expert/MarkNeomiExam")
        },
        {
          name: "Corrected Tests",
          path: "corrected-exams",
          component: () => import("../views/neomi/expert/CorrectedFromExpert")
        },
        {
          name: "Correct Neomi Test",
          path: "mark-neomi-test/:stu_id?/:exam_type?/:challenge_no?/:which_exam",
          component: () => import("../views/neomi/expert/ExpertMark")
        },
        // Start New Routes For Neomi Correct - Print - Etc
        {
          name: "Neomi Analytics",
          path: "neomi-exams-report",
          component: () => import("../views/neomi/neomi-admin-exams-reports/index")
        },
        {
          name: "Neomi Test",
          path: "neomi-exams-two",
          component: () => import("../views/neomi/new-neomi-for-admin/index")
        },
        {
          name: "Print Neomi Test",
          path: "print-neomi-exam-group/:exam_type/:challenge_no/:group_id",
          component: () => import("../views/neomi/print/index")
        },
        {
          name: "Neomi Test",
          path: "correct-neomi-exam/:student_id/:challenge_no/:exam_type",
          component: () => import("../views/neomi/new-neomi-for-admin/CorrectNeomiExam")
        },
        {
          name: "Print Neomi Test",
          path: "print-neomi-exam/:student_id/:challenge_no/:exam_type",
          component: () => import("../views/neomi/new-neomi-for-admin/PrintExam")
        },
        // test print and correct
        {
          name: "test-correct-print-neomi-exam",
          path: "test-correct-print-neomi-exam/:student_id/:challenge_no/:exam_type",
          component: () => import("../views/neomi/new-neomi-for-admin/TestCorrectAndPrint")
        },
        // End New Routes For Neomi Correct - Print - Etc
        {
          name: "3D",
          path: "image/:img_id",
          component: () => import("../views/neomi/NemoiRenderImages")
        },
        {
          name: "3D",
          path: "img/:img_id",
          component: () => import("../views/neomi/GroupsRnderImages")
        },
        {
          name:"Student Test",
          // name:"اختبار الطالب",
          path: "exam/:exam/:id",
          component: () => import("../views/courses/exam")
        },
        {
          name:"Student",
          // name:" الطالب",
          path: "score/review/:exam_type/:id",
          component: () => import("../views/courses/scoreReview")
        },
        //course-groups
        {
          name: "Course Groups",
          // name: "مجموعات الدورة",
          path: "course-groups/:id?",
          component: () => import("@/views/courses/course-groups")
        },

        {
          path: '/student-details/:id',
          name: 'Student Details',
          component: StudentDetails,
          props: true
        },

      ]
    },
    loginPage,
    introPage,
    // photosGallery,
    registerPage,
    resetPasswordPage,
    resultPage,
    exampage,
    registerGuest,
    startexam,
    guestReport,
    guestPage,
    orderNeomiKit,

    // lockPage,
    { path: "*", component: NotFound }
  ];
}

export default router;
