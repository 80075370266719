<template>
  <!-- comment for the developer:
make sure to convert data to be key,value format before passing to this component.
How to convert? >>>
  this.groupInfo = Object.entries(
    object or your data
  ).map(([key, value]) => {return { key, value }})

  -->
  <card>
    <table class="table table-striped text_nobreak w-100">
      <tbody style="text-align: initial">
        <slot name="other_data"></slot>
        <tr
          v-for="(item, key) in items"
          :key="key"
          class="w-100"
          :class="{
            'd-none': shouldHideData(item.key),
          }"
        >
          <th scope="row" :width="'30%'">
            {{ $t(`GlobalGroup.${item.key}`) }}
          </th>
          <td>
            <span>
              <slot name="custom_show"></slot>
            </span>
            <!--            <span v-if="typeof item.value === 'string' && item.value.search(/\n/g) > 0"-->
            <!--                  v-html="ntBr(item.value)"></span>-->
            <span
              v-if="
                item.key.includes('date') ||
                item.key.includes('close_at') ||
                item.key.includes('end_at') ||
                item.key.includes('starts') ||
                item.key.includes('expiration') ||
                item.key.includes('start') ||
                item.key.includes('created_at') ||
                item.key.includes('updated_at') ||
                item.key === 'from' ||
                item.key === 'to'
              "
              ><i class="el-icon-time"></i>
              {{ toLocalDatetime(item.value, "en") }}</span
            >

            <span class="text-break" v-else-if="item.key === 'gender'">
              {{
                item.value == "1"
                  ? $t("admin_dashboard_type.male")
                  : $t("admin_dashboard_type.female")
              }}
            </span>
            <!-- group students view  -->
            <span class="text-break" v-else-if="item.key === 'age_group'">
              {{ getAgeGroup(item.value) }}
            </span>
            <span class="text-break" v-else-if="item.key === 'status'">
              {{ getStatus(item.value) }}
            </span>
            <span class="text-break" v-else-if="item.key === 'days'">
              <span
                class="badge badge-success h6 mx-2 p-2 rounded-pill"
                v-for="(day, i) in item.value"
                :key="i"
              >
                {{ day }}
              </span>
            </span>
            <span class="text-break" v-else-if="item.key === 'course_name'">
              {{ getCourseTitle(item.value) }}
            </span>
            <span class="text-break" v-else>
              {{ item.value }}
            </span>
          </td>
        </tr>
        <slot name="bottom_other_data"></slot>
      </tbody>
    </table>
  </card>
</template>
<script>
export default {
  name: "info",
  props: {
    items: null,
  },
  methods: {
    shouldHideData(key) {
      const keysToHide = ["zoom_link","status","school_id","school","country", "city", "grade", "email"];
      return keysToHide.includes(key);
    },
    getAgeGroup(id) {
      switch (id) {
        case 1:
          return this.$t("less_than_6");
        case 2:
          return this.$t("from_6_to_8");
        case 3:
          return this.$t("from_9_to_12");
        case 4:
          return this.$t("more_than_13");
        default:
          return this.$t("all_ages");
      }
    },
    getStatus(id) {
      switch (id) {
        case 0:
          return "غير فعال";
        case 1:
          return "فعال";
        case 3:
          return "مخفي";
      }
    },
    getCourseTitle(title) {
      return this.getLocalizedText(title);
    },
    getCourseDescription(description) {
      return this.getLocalizedText(description);
    },
    getLessonTitle(title) {
      return this.getLocalizedText(title);
    },
    getLocalizedText(jsonString) {
      const locale = this.$i18n.locale;
      if (jsonString) {
        try {
          const parsedData = JSON.parse(jsonString);
          console.log("Parsed Data:", parsedData);
          return parsedData[locale] || parsedData["en"]; // Fallback to English if the locale is not found
        } catch (error) {
          console.error("Error parsing JSON:", error);
          return jsonString; // Return the original string if it's not valid JSON
        }
      } else {
        return "";
      }
    },
  },
};
</script>
<style></style>
