<template>
  <footer class="footer" :class="{ is_neomi: $route.name == 'اختبار نيومي' }">
    <div class="container-fluid d-flex flex-wrap justify-content-between">
      <nav>
        <ul class="footer-menu">
          <!--          <li>-->
          <!--            <router-link :to="{path:'/admin'}">Dashboard</router-link>-->
          <!--          </li>-->
        </ul>
      </nav>
      <div class="copyright">
        Developed by
        <a href="https://www.vero-as.com/" target="_blank" rel="noopener"
          >VERO</a
        >
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>

<style>
@media print {
  .is_neomi {
    display: none;
  }
}
</style>
