var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kit_order"},[_c('div',{staticClass:"container"},[_vm._m(0),_vm._m(1),_c('el-form',{ref:"orderKits",staticClass:"mt-5",staticStyle:{"direction":"ltr"},attrs:{"model":_vm.kitOrder,"rules":_vm.rules},on:{"submit":function($event){$event.preventDefault();return _vm.orderNeomiKit.apply(null, arguments)}}},[_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{attrs:{"md":12}},[_c('el-form-item',{attrs:{"prop":"entity","label":"Name of Institution"}},[_c('el-input',{staticStyle:{"width":"100%"},attrs:{"type":"text"},model:{value:(_vm.kitOrder.entity),callback:function ($$v) {_vm.$set(_vm.kitOrder, "entity", $$v)},expression:"kitOrder.entity"}})],1)],1),_c('el-col',{attrs:{"md":12}},[_c('el-form-item',{attrs:{"prop":"contact_name","label":"Contact Name"}},[_c('el-input',{staticStyle:{"width":"100%"},attrs:{"type":"text"},model:{value:(_vm.kitOrder.contact_name),callback:function ($$v) {_vm.$set(_vm.kitOrder, "contact_name", $$v)},expression:"kitOrder.contact_name"}})],1)],1),_c('el-col',{attrs:{"md":12}},[_c('el-form-item',{attrs:{"label":"Email","prop":"email","rules":[
              {
                required: true,
                message: 'This field is required',
                trigger: 'blur',
              },
              {
                type: 'email',
                message: 'Please input correct email address',
                trigger: ['blur', 'change'],
              } ]}},[_c('el-input',{staticStyle:{"width":"100%"},attrs:{"type":"text"},model:{value:(_vm.kitOrder.email),callback:function ($$v) {_vm.$set(_vm.kitOrder, "email", $$v)},expression:"kitOrder.email"}})],1)],1),_c('el-col',{attrs:{"md":12}},[_c('el-form-item',{attrs:{"prop":"phone_number","label":"Phone Number"}},[_c('el-input',{staticStyle:{"width":"100%"},attrs:{"type":"text"},model:{value:(_vm.kitOrder.phone_number),callback:function ($$v) {_vm.$set(_vm.kitOrder, "phone_number", $$v)},expression:"kitOrder.phone_number"}})],1)],1),_c('el-col',{attrs:{"md":12}},[_c('el-form-item',{attrs:{"prop":"kits_no","label":"Number of NEOMI kit(s)"}},[_c('el-input',{staticStyle:{"width":"100%"},attrs:{"type":"text"},model:{value:(_vm.kitOrder.kits_no),callback:function ($$v) {_vm.$set(_vm.kitOrder, "kits_no", $$v)},expression:"kitOrder.kits_no"}})],1)],1),_c('el-col',{attrs:{"md":12}},[_c('el-form-item',{attrs:{"prop":"address","label":"Address"}},[_c('el-input',{staticStyle:{"width":"100%"},attrs:{"type":"text"},model:{value:(_vm.kitOrder.address),callback:function ($$v) {_vm.$set(_vm.kitOrder, "address", $$v)},expression:"kitOrder.address"}})],1)],1),_c('el-col',{attrs:{"md":12}},[_c('el-form-item',{attrs:{"label":"Comment"}},[_c('el-input',{staticStyle:{"width":"100%"},attrs:{"type":"textarea"},model:{value:(_vm.kitOrder.comment),callback:function ($$v) {_vm.$set(_vm.kitOrder, "comment", $$v)},expression:"kitOrder.comment"}})],1)],1)],1),_c('div',{staticClass:"my-3 d-flex justify-content-center"},[_c('el-button',{attrs:{"disabled":_vm.process,"type":"primary"},on:{"click":_vm.orderNeomiKit}},[(!_vm.process)?_c('span',[_vm._v("ارسال")]):_vm._e(),(_vm.process)?_c('span',[_vm._v("انتظر")]):_vm._e()])],1)],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"part_one"},[_c('p',{staticClass:"text-center"},[_vm._v(" Please download the sample PDF file to test the app ")]),_c('p',{staticClass:"text-center"},[_c('span',[_vm._v("لتجربة التطبيق برجاء تنزيل ملف")]),_vm._v(" "),_c('span',[_vm._v("الـPDF")])]),_c('div',{staticClass:"d-flex justify-content-center mt-3"},[_c('a',{attrs:{"href":"https://justtolearn.com/api/public/samples","target":"_blank","download":""}},[_vm._v("Download Sample PDF")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"part_two mt-5"},[_c('p',{staticClass:"text-center"},[_vm._v(" To order NEOMI Kit(s) or to get more information about how to join the program, please fill in the following form ")]),_c('p',{staticClass:"text-center"},[_vm._v("لشراء لعبة نيومي برجاء اضافة بياناتك بالاسفل")])])}]

export { render, staticRenderFns }