<template>
  <div class="kit_order">
    <div class="container">
      <!-- https://justtolearn.com/api/public/samples -->
      <div class="part_one">
        <p class="text-center">
          Please download the sample PDF file to test the app
        </p>
        <p class="text-center">
          <span>لتجربة التطبيق برجاء تنزيل ملف</span> <span>الـPDF</span>
        </p>
        <div class="d-flex justify-content-center mt-3">
          <a
            href="https://justtolearn.com/api/public/samples"
            target="_blank"
            download
            >Download Sample PDF</a
          >
        </div>
      </div>
      <div class="part_two mt-5">
        <p class="text-center">
          To order NEOMI Kit(s) or to get more information about how to join the
          program, please fill in the following form
        </p>
        <p class="text-center">لشراء لعبة نيومي برجاء اضافة بياناتك بالاسفل</p>
      </div>

      <!-- Start Form  -->
      <el-form
        class="mt-5"
        style="direction: ltr"
        :model="kitOrder"
        ref="orderKits"
        :rules="rules"
        @submit.prevent="orderNeomiKit"
      >
        <el-row :gutter="10">
          <!-- <el-col :md="24" -->
          <el-col :md="12">
            <el-form-item prop="entity" label="Name of Institution">
              <el-input
                type="text"
                style="width: 100%"
                v-model="kitOrder.entity"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item prop="contact_name" label="Contact Name">
              <el-input
                type="text"
                style="width: 100%"
                v-model="kitOrder.contact_name"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item
              label="Email"
              prop="email"
              :rules="[
                {
                  required: true,
                  message: 'This field is required',
                  trigger: 'blur',
                },
                {
                  type: 'email',
                  message: 'Please input correct email address',
                  trigger: ['blur', 'change'],
                },
              ]"
            >
              <el-input
                type="text"
                style="width: 100%"
                v-model="kitOrder.email"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item prop="phone_number" label="Phone Number">
              <el-input
                type="text"
                style="width: 100%"
                v-model="kitOrder.phone_number"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item prop="kits_no" label="Number of NEOMI kit(s)">
              <el-input
                type="text"
                style="width: 100%"
                v-model="kitOrder.kits_no"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item prop="address" label="Address">
              <el-input
                type="text"
                style="width: 100%"
                v-model="kitOrder.address"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item label="Comment">
              <el-input
                type="textarea"
                style="width: 100%"
                v-model="kitOrder.comment"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="my-3 d-flex justify-content-center">
          <el-button :disabled="process" @click="orderNeomiKit" type="primary"
            ><span v-if="!process">ارسال</span
            ><span v-if="process">انتظر</span></el-button
          >
        </div>
      </el-form>
      <!-- End Form  -->
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      // email-- entity--kits_no--contact_name--phone_number--address--comment
      kitOrder: {
        email: null,
        entity: null,
        kits_no: null,
        contact_name: null,
        phone_number: null,
        address: null,
        comment: null,
      },
      rules: {
        email: [
          {
            required: true,
            message: "This field is required",
            trigger: "change",
          },
        ],
        email: [
          {
            required: true,
            type: "email",
            message: [
              "This field is required",
              "Please input correct email address",
            ],
            trigger: ["blur", "change"],
          },
        ],
        entity: [
          {
            required: true,
            message: "This field is required",
            trigger: "blur",
          },
        ],
        kits_no: [
          {
            required: true,
            message: "This field is required",
            trigger: "blur",
          },
        ],
        contact_name: [
          {
            required: true,
            message: "This field is required",
            trigger: "blur",
          },
        ],
        phone_number: [
          {
            required: true,
            message: "This field is required",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "This field is required",
            trigger: "blur",
          },
        ],
        // comment: [
        //   {
        //     required: true,
        //     message: "هذا الحقل مطلوب",
        //     trigger: "blur",
        //   },
        // ],
      },
      process: false,
    };
  },
  methods: {
    orderNeomiKit() {
      // contactNemoiKit
      this.$refs["orderKits"].validate((valid) => {
        if (valid) {
          this.process = true;
          this.$store
            .dispatch("courses/contactNemoiKit", this.kitOrder)
            .then(() => {
              this.process = false;
              Swal.fire({
                title: "",
                icon: "success",
                text: `تم`,
                confirmButtonText: `موافق`,
                type: "success",
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false,
              });
              this.kitOrder.email = null;
              this.kitOrder.entity = null;
              this.kitOrder.kits_no = null;
              this.kitOrder.contact_name = null;
              this.kitOrder.address = null;
              this.kitOrder.comment = null;
              this.kitOrder.phone_number = null;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.kit_order {
  min-height: 100vh;
  background: #f9f9f9;
  .part_one {
    padding-top: 3rem;
    color: #555;
  }
  p {
    margin-bottom: 5px;
  }
  a {
    color: #fff;
    background-color: #67c23a;
    border-color: #67c23a;
    padding: 8px 20px;
    border-radius: 6px;
    text-decoration: none;
  }
}

::v-deep .el-form-item__error {
  // right: 0;
  // left: auto;
}
::v-deep .el-textarea__inner {
  min-height: 150px !important;
  resize: none;
}
</style>
